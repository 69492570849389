import './style.scss';
import 'mdui/mdui.css';

//components
import 'mdui/components/layout.js';
import 'mdui/components/layout-item.js';
import 'mdui/components/layout-main.js';
import 'mdui/components/top-app-bar.js';
import 'mdui/components/top-app-bar-title.js';
import 'mdui/components/navigation-drawer.js';
import 'mdui/components/avatar.js';
import 'mdui/components/button.js';
import 'mdui/components/button-icon.js';
import 'mdui/components/card.js';
import 'mdui/components/chip.js';
import 'mdui/components/menu.js';
import 'mdui/components/menu-item.js';
import 'mdui/components/dropdown.js';
import 'mdui/components/list.js';
import 'mdui/components/list-item.js';
import 'mdui/components/list-subheader.js';
import 'mdui/components/snackbar.js';
import 'mdui/components/text-field.js';
import 'mdui/components/switch.js';

//function components
import { snackbar } from 'mdui/functions/snackbar.js';

//icon site
import '@mdui/icons/account-circle--rounded.js';
import '@mdui/icons/menu--rounded.js';
import '@mdui/icons/arrow-outward--rounded.js';
import '@mdui/icons/arrow-back-ios.js';
//icon menu
import '@mdui/icons/home--outlined.js';
import '@mdui/icons/newspaper.js';
import '@mdui/icons/school--outlined.js';
import '@mdui/icons/thumb-up--outlined.js';
import '@mdui/icons/downloading--outlined.js';
import '@mdui/icons/mail--outlined.js';
import '@mdui/icons/text-snippet--outlined.js';
import '@mdui/icons/upload-file--outlined.js';
import '@mdui/icons/content-copy.js';
import '@mdui/icons/edit-calendar--outlined.js';
import '@mdui/icons/playlist-add-circle--outlined.js';
import '@mdui/icons/settings-applications--outlined.js';
import '@mdui/icons/info--outlined.js';
import '@mdui/icons/privacy-tip--outlined.js';
import '@mdui/icons/feedback--outlined.js';


/*
* fa apparire lo snackbar come conferma del logout
 */
document.addEventListener('DOMContentLoaded', function() {
    const params = new URLSearchParams(window.location.search);
    const outParam = params.get('out');

    if (outParam === 'logout') {
        snackbar({
            message: 'Logout effettuato',
            placement: 'top',
            closeable: true,
            queue: 'sys'
        });
    }
    if (outParam === 'reset-pass') {
        snackbar({
            message: 'Inviata email per reset password',
            placement: 'top',
            closeable: true,
            queue: 'sys'
        });
    }
});